.footer {
  background: black;
  margin-top: 70px;
}
.footer-wrapper{
    display: flex;
    flex-direction: row;
    gap: 35px;
  }

.footer-title-img {
  width: 235px;
  padding-left: 12px;
}

.footer form {
  margin-top: 0px;
}

.footer form input[type="text"] {
  font-family: 'Exo 2', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  border: none;
  border-bottom: 1px solid #5D5D5D;
  padding-bottom: 10px;
  width: 100%;
  background: transparent;
  margin-bottom: 28px;
  color: white;
}

.footer .red-border {
  border-bottom: 1px solid rgba(182, 13, 13, 1) !important;
}

.footer form input::placeholder {
  font-family: 'Exo 2', serif;
  color: rgba(140, 139, 147, 1);
  font-size: 16px;
  font-weight: 300;
  text-indent: -2px;
  overflow: visible;
}

.footer form input:focus {
  outline: none;
}

.footer form .validation-error {
  color: rgba(182, 13, 13, 1);
  position: absolute;
  margin-top: -30px;
  font-size: 12px;
  font-family: 'Exo 2', serif;
  font-weight: 300;
}

.footer form .send-button {
  position: relative;
  background: rgb(255, 255, 255);
  width: 90px;
  height: 30px;
  border: none;
  border-radius: 24px;
  scale: 1;
  transition: all 250ms ease;
}

.footer form .send-button img{
  position: absolute;
  width: 90px;
  height: 30px;
  top: 0;
  left: 0;
}

.footer form .send-button .button__text {
  font-family: 'Exo 2', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #0C0C0C;
  transition: all 0.2s;
}

.footer form .button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.footer form .button--loading::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: rgba(0, 0, 0, 1);
  border-left-color: rgba(0, 0, 0, 1);
  border-right-color: rgba(0, 0, 0, 1);
  border-radius: 50%;
  animation: button-loading-spinner 0.7s linear infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.footer form .send-button:hover {
  transform: scale(1.03)
}

.footer form .send-button:active {
  transform: scale(1.05)
}

.footer form .submit-cont {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
}

.footer .submitting-error {
  position: absolute;
  font-family: 'Exo 2', serif;
  color: rgba(182, 13, 13, 1);
  font-weight: 300;
  line-height: 17px;
  font-size: 14px;
  left: 0;
  top: -25px;
  width: unset;
}

.social-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}
.social-wrapper{
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
}
.social-label{
  padding: 0px;
  margin: 0px;
  color: rgb(140, 139, 147);
  font-family: 'Exo 2', serif;
  font-size: 20px;
  font-weight: 300;
  text-align: left;
}
.social-contacts-link{
  text-decoration: none;
  padding: 0px;
  margin: 0px;
}
.networks{
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.networks img{
  width: 22px;
  cursor: pointer;
}

.social-contacts{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer .third-cont span {
  font-family: 'Exo 2', serif;
  font-size: 21px;
  font-weight: 300;
  line-height: 28.8px;
  display: block;
  color: #FFFFFF;
  letter-spacing: 0.1px;
}

.footer .fourth-cont {
  margin-top: 30px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 80px 0px 80px;
}

.footer .fourth-cont .copyright span {
  font-family: 'Exo 2', serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  color: #8C8B93;
  margin-left: 9px;
}
.footer .fourth-cont .to-top {
  cursor: pointer;
}

.footer .fourth-cont .to-top span {
  font-family: 'Exo 2', serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #8C8B93;
  margin-right: 9px;
}

.footer .fourth-cont .copyright img, .footer .fourth-cont .to-top img {
  width: 22px;
  fill: #8C8B93;
  stroke: #8C8B93;
}

@media screen and (max-width: 767px){
  .footer-wrapper{
    display: flex;
    flex-direction: column;
    gap: 35px;
  }
  .footer-title-img {
    width: 100%;
    padding-left: 0px;
  }
  .social-label{
    font-size: 17px;
  }
  .footer .third-cont span{
    font-size: 17px;
  }
  .social-contacts{
    flex-direction: row;
    align-items: flex-start;
    gap: 15px;
  }
  .social-container{
    align-items: flex-start;
  }
  .social-contacts-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: -1px;
  }
  .footer form .submit-cont {
    justify-content: center;
  }
  .footer .fourth-cont{
    padding: 0px 12px 0px 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .footer-title-img {
  width: 175px;
  padding-left: 4px;
}
.social-container{
  align-items: start;
}
  .social-label{
    font-size: 16px;
  }
  .footer .third-cont span{
    font-size: 18px;
  }
  .footer .fourth-cont{
    padding: 0px 14px 0px 14px;
  }
}
