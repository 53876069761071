@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Home Video';
  src: url('fonts/HomeVideo-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('fonts/Exo2-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('fonts/Exo2-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('fonts/Exo2-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('fonts/Exo2-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('fonts/Exo2-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nova Mono';
  src: url('fonts/NovaMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "Exo 2", serif;
  background: black !important;
  min-width: 305px;
}

a {
  text-decoration: none;
}
