.about {
  margin-top: 121px;
  min-height: calc(100vh - 740px);
}

.about .title-about h1 {
  font-family: 'Exo 2', serif;
  font-size: 40px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 4px;
  letter-spacing: 0.4px;
}

.about .title-about h2 {
  font-family: 'Exo 2', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  margin-top: 45px;
  margin-left: 4px;
  letter-spacing: 0.1px;
}

.about .title-about p {
  font-family: 'Exo 2', serif;
  font-size: 16px;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  line-height: 24px;
  margin-left: 4px;
  margin-top: 24px;
  letter-spacing: 0.1px;
}

.about .title-about h3 {
  font-family: 'Exo 2', serif;
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  line-height: 24px;
  margin-left: 4px;
  margin-top: 24px;
  margin-bottom: 0;
  letter-spacing: 0.1px;
}

.about .title-about span {
  font-family: 'Exo 2', serif;
  font-size: 16px;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  line-height: 24px;
  margin-top: 0px;
  margin-left: 4px;
  letter-spacing: 0.1px;
  display: block;
}

.show-reel {
  margin-top: 40px;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .about {
    min-height: calc(100vh - 740px);
  }

  .about .title-about h2 {
    font-size: 18px;
    margin-top: 24px;
  }

  .about .title-about p {
    font-size: 18px;
    margin-top: 26px;
  }

  .about .title-about h3 {
    font-size: 18px;
    margin-top: 26px;
  }

  .about .title-about span {
    font-size: 18px;
  }
}


@media screen and (min-width: 768px) and (max-width: 991px) {
  .about {
    margin-top: 120px;
    min-height: calc(100vh - 490px);
  }

  .about .title-about {
    width: 86%;
    margin: auto;
  }

  .about .title-about h1 {
    font-size: 40px;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 0px;
  }

  .about .title-about h2 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 45px;
    margin-left: 0px;
    letter-spacing: 0.2px;
  }

  .about .title-about p {
    font-size: 20px;
    font-weight: 300;
    margin-top: 26px;
    line-height: 36px;
    margin-left: 0px;
  }

  .about .title-about h3 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
    margin-left: 0px;
    letter-spacing: 0.2px;
  }

  .about .title-about span {
    font-size: 20px;
    font-weight: 300;
    margin-top: 8px;
    margin-left: 0px;
  }
}


@media screen and (min-width: 992px) and (max-width: 1199px) {
  .about {
    margin-top: 150px;
    min-height: calc(100vh - 570px);
  }

  .about .title-about {
    width: 80%;
    margin: auto;
  }

  .about .title-about h1 {
    font-size: 40px;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 0px;
  }

  .about .title-about h2 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 45px;
    margin-left: 0px;
    letter-spacing: 0.2px;
  }

  .about .title-about p {
    font-size: 22px;
    font-weight: 300;
    margin-top: 30px;
    line-height: 36px;
    margin-left: 0px;
  }

  .about .title-about h3 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 38px;
    margin-left: 0px;
    letter-spacing: 0.2px;
  }

  .about .title-about span {
    font-size: 22px;
    font-weight: 300;
    margin-top: 10px;
    margin-left: 0px;
  }
}


@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .about {
    margin-top: 170px;
    min-height: calc(100vh - 670px);
  }

  .about .title-about {
    width: 80%;
    margin: auto;
  }

  .about .title-about h1 {
    font-size: 40px;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 0px;
  }

  .about .title-about h2 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 45px;
    margin-left: 0px;
    letter-spacing: 0.2px;
  }

  .about .title-about p {
    font-size: 24px;
    font-weight: 300;
    margin-top: 42px;
    line-height: 36px;
    margin-left: 0px;
  }

  .about .title-about h3 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 42px;
    margin-left: 0px;
    letter-spacing: 0.2px;
  }

  .about .title-about span {
    font-size: 24px;
    font-weight: 300;
    margin-top: 12px;
    margin-left: 0px;
  }
}

@media screen and (min-width: 1400px) {
  .about {
    margin-top: 186px;
    min-height: calc(100vh - 700px);
  }

  .about .title-about {
    width: 82%;
    margin: auto;
  }

  .about .title-about h1 {
    font-size: 40px;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 0px;
  }

  .about .title-about h2 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 45px;
    margin-left: 0px;
    letter-spacing: 0.2px;
  }

  .about .title-about p {
    font-size: 24px;
    font-weight: 300;
    margin-top: 42px;
    line-height: 36px;
    margin-left: 0px;
  }

  .about .title-about h3 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 42px;
    margin-left: 0px;
    letter-spacing: 0.2px;
  }

  .about .title-about span {
    font-size: 24px;
    font-weight: 300;
    margin-top: 12px;
    margin-left: 0px;
  }
}
