.showReelAbout .mainCont {
  position: relative;
  overflow: hidden;
  height: fit-content;
  margin: auto;
}

.showReelAbout .mainCont .womenImg {
  width: 100%;
}

.showReelAbout .mainCont button {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 1);
  width: 204px;
  height: 56px;
  transition: transform 0.2s ease-in-out, background 0.1s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
}

.showReelAbout .mainCont button:hover {
  transform: translate(-50%, -50%) scale(1.03);
}

.showReelAbout .mainCont button:active {
  background: rgba(0, 0, 0, 0.1);
}

.showReelAbout .mainCont button span {
  font-family: 'Exo 2', serif;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-left: 10px;
}

.showReelAbout .mainCont button img {
  margin-top: -4px;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .showReelAbout .mainCont {
    margin-top: 70px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .showReelAbout .mainCont {
    width: 86%;
    margin-top: 80px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .showReelAbout .mainCont {
    width: 80%;
    margin-top: 100px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .showReelAbout .mainCont {
    width: 80%;
    margin-top: 110px;
  }

  .showReelAbout .mainCont button {
    width: 239px;
    height: 62px;
  }

  .showReelAbout .mainCont button span {
    font-size: 20px;
    font-weight: 300;
  }

  .showReelAbout .mainCont button img {
    margin-top: -8px;
  }
}

@media screen and (min-width: 1400px) {
  .showReelAbout .mainCont {
    width: 82%;
    margin-top: 125px;
  }

  .showReelAbout .mainCont button {
    width: 239px;
    height: 62px;
  }

  .showReelAbout .mainCont button span {
    font-size: 20px;
    font-weight: 300;
  }

  .showReelAbout .mainCont button img {
    margin-top: -8px;
  }
}
