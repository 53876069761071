.modalReel {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: 0.3s;
  z-index: 101;
  opacity: 0;
  display: flex;
  align-items: center;
}

.modalReel.active {
  opacity: 1;
  pointer-events: all;
}

.modalReel .content {
  transition: 0.4s all;
  z-index: 102;
  width: 100%;
  padding: 0 4px;
  overflow: scroll;
}

.modalReel .content::-webkit-scrollbar {
  display: none;
}

.modalReel .content.active {
  transform: scale(1, 1);
}

.modalReel .headCont {
  display: flex;
  justify-content: end;
}

.modalReel img {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.modalReel iframe {
  width: 100%;
  height: 64vw;
  max-height: 75vh;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .modalReel .content {
    padding: 20px 28px;
    height: 100%;
  }

  .modalReel img {
    height: 34px;
    width: 34px;
  }

  .modalReel iframe {
    width: 100%;
    height: 316px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .modalReel .content {
    padding: 40px 48px;
    height: 100%;
  }

  .modalReel img {
    height: 34px;
    width: 34px;
  }

  .modalReel iframe {
    margin-top: 16px;
    width: 100%;
    height: 386px;
    max-height: 80vh;

  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .modalReel {
    align-items: unset;
  }

  .modalReel .content {
    padding: 40px 48px;
    height: 100%;
  }

  .modalReel img {
    height: 38px;
    width: 38px;
  }

  .modalReel iframe {
    margin-top: 16px;
    width: 100%;
    height: 524px;
    max-height: 80vh;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .modalReel {
    align-items: unset;
  }

  .modalReel .content {
    padding: 40px 48px;
    height: 100%;
  }

  .modalReel img {
    height: 40px;
    width: 40px;
  }

  .modalReel iframe {
    margin-top: 16px;
    width: 100%;
    height: 630px;
    max-height: 80vh;
  }
}

@media screen and (min-width: 1400px) {
  .modalReel {
    align-items: unset;
  }

  .modalReel .content {
    padding: 24px 68px;
    height: 100%;
  }

  .modalReel img {
    height: 40px;
    width: 40px;
  }

  .modalReel iframe {
    margin-top: 16px;
    width: 100%;
    height: 730px;
    max-height: 80vh;   
  }
}

@media screen and (max-width: 575px) and (max-height: 430px) {
  .modalReel .content {
    padding-top: 24px;
    padding-bottom: 24px;
    height: 100%;
  }
}
