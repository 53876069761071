.showReel .mainCont {
  position: relative;
  overflow: hidden;
}

.showReel .mainCont .womenImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
}

.showReel .mainCont button {
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 1);
  width: 204px;
  height: 56px;
  transition: transform 0.2s ease-in-out, background 0.1s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
}

.showReel .mainCont button:hover {
  transform: translate(-50%, -50%) scale(1.03);
}

.showReel .mainCont button:active {
  background: rgba(0, 0, 0, 0.1);
}

.showReel .mainCont button span {
  font-family: 'Exo 2', serif;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-left: 10px;
}

.showReel .mainCont button img {
  margin-top: -4px;
}

@media screen  and (max-width: 767px) {
 .showReel .mainCont {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 120px;
  }
  .showReel .mainCont .womenImg {
    width: 100%;
    position: relative;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .showReel .mainCont {
    position: relative;
    overflow: hidden;
  }
  
  .showReel .mainCont .womenImg {
    width: 100%;
    position: relative;
    height: unset;
    transform: unset;
    left: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .showReel .mainCont {
    position: relative;
    overflow: hidden;
    height: fit-content;
  }
  
  .showReel .mainCont .womenImg {
    width: 100%;
    position: relative;
    height: unset;
    transform: unset;
    left: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .showReel .mainCont {
    position: relative;
    overflow: hidden;
    height: fit-content;
  }
  
  .showReel .mainCont .womenImg {
    width: 100%;
    position: relative;
    height: unset;
    transform: unset;
    left: 0;
  }

  .showReel .mainCont button {
    width: 239px;
    height: 62px;
  }

  .showReel .mainCont button span {
    font-size: 20px;
    font-weight: 300;
  }

  .showReel .mainCont button img {
    margin-top: -8px;
  }
}

@media screen and (min-width: 1400px) {
  .showReel .mainCont {
    position: relative;
    overflow: hidden;
    height: fit-content;
  }
  
  .showReel .mainCont .womenImg {
    width: 100%;
    position: relative;
    height: unset;
    transform: unset;
    left: 0;
  }

  .showReel .mainCont button {
    width: 239px;
    height: 62px;
  }

  .showReel .mainCont button span {
    font-size: 20px;
    font-weight: 300;
  }

  .showReel .mainCont button img {
    margin-top: -8px;
  }
}
