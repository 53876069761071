.portfolio {
  margin-top: 121px;
  min-height: calc(100vh - 740px);
}

.portfolio h1 {
  font-family: 'Exo 2', serif;
  font-size: 40px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 4px;
  letter-spacing: 0.4px;
}

.portfolio ul {
  padding-left: 4px;
  margin-top: 15px;
  margin-bottom: -8px;
}

.portfolio ul li {
  font-family: 'Exo 2', serif;
  font-size: 14px;
  font-weight: 300;
  color: rgba(93, 93, 93, 1);
  cursor: pointer;
  list-style-type: none;
  display: inline-block;
  margin-right: 9px;
}

.portfolio ul .li-active {
  color: rgba(255, 255, 255, 1);
}

.portfolio .works-cont {
  margin-top: 15px;
  padding: 0 4px;
}

.portfolio .work-cont {
  margin-top: 12px;
  padding: 0 4px;
}

.portfolio .work-cont .image-cont {
  position: relative;
  margin-top: 16px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.portfolio .work-cont .work-img {
  width: 100%;
  transition: 0.3s;
  display: block
}

.portfolio .work-cont .mask-cont {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s;
}

.portfolio .work-cont .mask-cont .corner1,
.portfolio .work-cont .mask-cont .corner2,
.portfolio .work-cont .mask-cont::before,
.portfolio .work-cont .mask-cont::after {
  content: '';
  position: absolute;
  width: 8vw;
  height: 8vw;
  border: 1px solid white;
  z-index: 1;
}

.portfolio .work-cont .mask-cont::before {
  top: 3vw;
  left: 3vw;
  border-bottom: none;
  border-right: none;
}

.portfolio .work-cont .mask-cont::after {
  bottom: 3vw;
  right: 3vw;
  border-top: none;
  border-left: none;
}

.portfolio .work-cont .mask-cont .corner1 {
  top: 3vw;
  right: 3vw;
  border-bottom: none;
  border-left: none;
}

.portfolio .work-cont .mask-cont .corner2 {
  bottom: 3vw;
  left: 3vw;
  border-top: none;
  border-right: none;
}

.portfolio .work-cont .mask-cont .title {
  position: absolute;
  top: 8vw;
  left: 8vw;
}

.portfolio .work-cont .mask-cont .title h3 {
  font-family: 'Nova Mono', serif;
  font-size: 4vw;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
}

.portfolio .work-cont .mask-cont .title p {
  font-family: 'Nova Mono', serif;
  font-size: 4vw;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
}

.portfolio .work-cont .mask-cont .play {
  position: absolute;
  bottom: 8.8vw;
  right: 8vw;
  display: flex;
}

.portfolio .work-cont .mask-cont .play img {
  margin-right: 1.5vw;
  width: 4vw;
  margin-bottom: -0.5vw;
}

.portfolio .work-cont .mask-cont .play span {
  font-family: 'Nova Mono', serif;
  font-size: 4vw;
  line-height: 4vw;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 305px)  {
  .portfolio ul {
    width: 200px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .portfolio {
    min-height: calc(100vh - 740px);
  }

  .portfolio .title h1 {
    letter-spacing: 0;
    font-weight: 700;
  }

  .portfolio .work-cont .mask-cont .corner1,
  .portfolio .work-cont .mask-cont .corner2,
  .portfolio .work-cont .mask-cont::before,
  .portfolio .work-cont .mask-cont::after {
    width: 40px;
    height: 40px;
  }

  .portfolio .work-cont .mask-cont::before {
    top: 18px;
    left: 18px;
  }

  .portfolio .work-cont .mask-cont::after {
    bottom: 18px;
    right: 18px;
  }

  .portfolio .work-cont .mask-cont .corner1 {
    top: 18px;
    right: 18px;
  }

  .portfolio .work-cont .mask-cont .corner2 {
    bottom: 18px;
    left: 18px;
  }

  .portfolio .work-cont .mask-cont .title {
    top: 42px;
    left: 42px;
  }

  .portfolio .work-cont .mask-cont .title h3 {
    font-size: 21px;
  }

  .portfolio .work-cont .mask-cont .title p {
    font-size: 21px;
    margin-top: 2px;
  }

  .portfolio .work-cont .mask-cont .play {
    bottom: 46px;
    right: 42px;
  }

  .portfolio .work-cont .mask-cont .play img {
    margin-right: 10px;
    width: 21px;
    margin-bottom: -3px;
  }

  .portfolio .work-cont .mask-cont .play span {
    font-size: 21px;
    line-height: 21px;
  }

  .portfolio .work-cont .image-cont {
    margin-top: 12px;
    position: relative;
    height: 271px
  }

  .portfolio .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio {
    margin-top: 120px;
    min-height: calc(100vh - 490px);
  }

  .portfolio .title h1 {
    font-size: 40px;
    letter-spacing: 0;
    font-weight: 700;
  }

  .portfolio .work-parent {
    padding: 0 6px;
  }

  .portfolio .works-cont {
    margin-top: 15px;
  }

  .portfolio .work-cont {
    padding: 0;
  }

  .portfolio .work-cont .image-cont {
    margin-top: 12px;
    position: relative;
    height: 193.5px
  }

  .portfolio .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .portfolio .work-cont .mask-cont .corner1,
  .portfolio .work-cont .mask-cont .corner2,
  .portfolio .work-cont .mask-cont::before,
  .portfolio .work-cont .mask-cont::after {
    width: 30px;
    height: 30px;
  }

  .portfolio .work-cont .mask-cont::before {
    top: 12px;
    left: 12px;
  }

  .portfolio .work-cont .mask-cont::after {
    bottom: 12px;
    right: 12px;
  }

  .portfolio .work-cont .mask-cont .corner1 {
    top: 12px;
    right: 12px;
  }

  .portfolio .work-cont .mask-cont .corner2 {
    bottom: 12px;
    left: 12px;
  }

  .portfolio .work-cont .mask-cont .title {
    top: 28px;
    left: 28px;
  }

  .portfolio .work-cont .mask-cont .title h3 {
    font-size: 18px;
  }

  .portfolio .work-cont .mask-cont .title p {
    font-size: 18px;
    margin-top: 2px;
  }

  .portfolio .work-cont .mask-cont .play {
    bottom: 32px;
    right: 28px;
  }

  .portfolio .work-cont .mask-cont .play img {
    margin-right: 10px;
    width: 18px;
    margin-bottom: -3px;
  }

  .portfolio .work-cont .mask-cont .play span {
    font-size: 18px;
    line-height: 18px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio .title h1 {
    font-size: 56px;
    letter-spacing: 0;
    font-weight: 700;
  }

  .portfolio {
    margin-top: 150px;
    min-height: calc(100vh - 570px);
  }

  .portfolio .title {
    margin-bottom: 18px;
  }

  .portfolio ul {
    text-align: right;
    margin-top: 20px;
  }

  .portfolio ul li {
    font-size: 16px;
    margin-left: 6px;
  }

  .portfolio .work-parent {
    padding: 0 8px;
  }

  .portfolio .works-cont {
    margin-top: 35px;
    padding: 0px 8px;
  }

  .portfolio .work-cont {
    padding: 0;
  }

  .portfolio .work-cont .image-cont {
    margin-top: 16px;
    position: relative;
    height: 256.5px
  }

  .portfolio .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .portfolio .work-cont .mask-cont .corner1,
  .portfolio .work-cont .mask-cont .corner2,
  .portfolio .work-cont .mask-cont::before,
  .portfolio .work-cont .mask-cont::after {
    width: 35px;
    height: 35px;
  }

  .portfolio .work-cont .mask-cont::before {
    top: 15px;
    left: 15px;
  }

  .portfolio .work-cont .mask-cont::after {
    bottom: 15px;
    right: 15px;
  }

  .portfolio .work-cont .mask-cont .corner1 {
    top: 15px;
    right: 15px;
  }

  .portfolio .work-cont .mask-cont .corner2 {
    bottom: 15px;
    left: 15px;
  }

  .portfolio .work-cont .mask-cont .title {
    top: 36px;
    left: 34px;
  }

  .portfolio .work-cont .mask-cont .title h3 {
    font-size: 19px;
  }

  .portfolio .work-cont .mask-cont .title p {
    font-size: 19px;
    margin-top: 2px;
  }

  .portfolio .work-cont .mask-cont .play {
    bottom: 38px;
    right: 32px;
  }

  .portfolio .work-cont .mask-cont .play img {
    margin-right: 8px;
    width: 19px;
    margin-bottom: -3px;
  }

  .portfolio .work-cont .mask-cont .play span {
    font-size: 19px;
    line-height: 19px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .portfolio {
    margin-top: 170px;
    min-height: calc(100vh - 670px);
  }

  .portfolio .title {
    margin-bottom: 22px;
  }

  .portfolio .title h1 {
    font-size: 64px;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 9px;
  }

  .portfolio ul {
    text-align: right;
    margin-top: 20px;
  }

  .portfolio ul li {
    font-size: 16px;
    margin-left: 7px;
  }

  .portfolio .work-parent {
    padding: 0 10px;
  }

  .portfolio .works-cont {
    margin-top: 35px;
    padding: 0px 8px;
  }

  .portfolio .work-cont {
    padding: 0;
  }

  .portfolio .work-cont .image-cont {
    margin-top: 20px;
    position: relative;
    height: 305px
  }

  .portfolio .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .portfolio .work-cont .mask-cont .corner1,
  .portfolio .work-cont .mask-cont .corner2,
  .portfolio .work-cont .mask-cont::before,
  .portfolio .work-cont .mask-cont::after {
    width: 50px;
    height: 50px;
  }

  .portfolio .work-cont .mask-cont::before {
    top: 20px;
    left: 20px;
  }

  .portfolio .work-cont .mask-cont::after {
    bottom: 20px;
    right: 20px;
  }

  .portfolio .work-cont .mask-cont .corner1 {
    top: 20px;
    right: 20px;
  }

  .portfolio .work-cont .mask-cont .corner2 {
    bottom: 20px;
    left: 20px;
  }

  .portfolio .work-cont .mask-cont .title {
    top: 50px;
    left: 46px;
  }

  .portfolio .work-cont .mask-cont .title h3 {
    font-size: 24px;
  }

  .portfolio .work-cont .mask-cont .title p {
    font-size: 24px;
    margin-top: 3px;
  }

  .portfolio .work-cont .mask-cont .play {
    bottom: 52px;
    right: 46px;
  }

  .portfolio .work-cont .mask-cont .play img {
    margin-right: 12px;
    width: 24px;
    margin-bottom: -4px;
  }

  .portfolio .work-cont .mask-cont .play span {
    font-size: 24px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1400px) {
  .portfolio {
    margin-top: 186px;
    min-height: calc(100vh - 700px);
  }

  .portfolio .title {
    margin-bottom: 28px;
  }

  .portfolio .title h1 {
    font-size: 64px;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 9px;
  }

  .portfolio ul {
    text-align: right;
    margin-top: 20px;
  }

  .portfolio ul li {
    font-size: 16px;
    margin-left: 8px;
  }

  .portfolio .works-cont {
    margin-top: 35px;
    padding: 0px 8px;
  }

  .portfolio .work-cont {
    margin-top: 22px;
    padding: 0;
  }

  .portfolio .work-cont .image-cont {
    margin-top: 20px;
    position: relative;
    height: 353px
  }

  .portfolio .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .portfolio .work-cont .mask-cont .corner1,
  .portfolio .work-cont .mask-cont .corner2,
  .portfolio .work-cont .mask-cont::before,
  .portfolio .work-cont .mask-cont::after {
    width: 55px;
    height: 55px;
  }

  .portfolio .work-cont .mask-cont::before {
    top: 24px;
    left: 24px;
  }

  .portfolio .work-cont .mask-cont::after {
    bottom: 24px;
    right: 24px;
  }

  .portfolio .work-cont .mask-cont .corner1 {
    top: 24px;
    right: 24px;
  }

  .portfolio .work-cont .mask-cont .corner2 {
    bottom: 24px;
    left: 24px;
  }

  .portfolio .work-cont .mask-cont .title {
    top: 58px;
    left: 52px;
  }

  .portfolio .work-cont .mask-cont .title h3 {
    font-size: 26px;
  }

  .portfolio .work-cont .mask-cont .title p {
    font-size: 26px;
    margin-top: 3px;
  }

  .portfolio .work-cont .mask-cont .play {
    bottom: 62px;
    right: 52px;
  }

  .portfolio .work-cont .mask-cont .play img {
    margin-right: 12px;
    width: 26px;
    margin-bottom: -4px;
  }

  .portfolio .work-cont .mask-cont .play span {
    font-size: 26px;
    line-height: 26px;
  }
}
