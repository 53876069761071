.alert {
  opacity: 0;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10000;
  color: rgba(0, 0, 0, 1);
  width: fit-content;
  min-height: 49px;
  font-family: 'Exo 2', serif;
  font-size: 14px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  display: flex;
  transform: translateY(-100px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 1s ease;
  margin-top: 11px;
  padding: 5px 16px;
}

@media screen and (min-width: 0px) and (max-width: 342px) {
  .alert {
    width: 70%;
  }
}


@media screen and (min-width: 992px) and (max-width: 1199px) {
  .alert {
    height: 50px;
    font-size: 15px;
    margin-top: 19px;
    padding: 5px 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .alert {
    height: 54px;
    font-size: 18px;
    margin-top: 19px;
    padding: 5px 23px;
  }
}

@media screen and (min-width: 1400px) {
  .alert {
    height: 54px;
    font-size: 18px;
    margin-top: 19px;
    padding: 5px 23px;
  }
}
