.header {
  top: 0px;
  position: fixed;
  background: black;
  width: 100%;
  z-index: 10;
  padding: 28px 80px 28px 80px;
}

.header .header-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  
}


.logo{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}


.header .left-cont .logo-gif {
  height: 17px;
}

.header .left-cont .logo-name {
  height: 13px;
}

.header .mid-cont {
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-left: -40px;
}

.header .mid-cont img {
  cursor: pointer;
}

.header .right-cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;
}

.header .right-cont button {
  background: transparent;
  border: none;
  transition: all 0.2s ease;
  scale: 1;
  padding: 0px;
}

.header .right-cont button:hover {
  scale: 1.03;
}

.header .right-cont button:active {
  background: transparent;
}

.header .right-cont img {
  cursor: pointer;
  width: 90px;
}

.header .right-cont .burger-btn{
  width: 40px;
  height: 20px;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: end;
  align-items: center;
  /* border: 1px solid red; */
}

.header .right-cont .burger{
  width: 10px;
  height: 24px;
  transition: transform 0.2s ease-in-out;
}


.header .right-cont .burger:hover{
  transform: scale(1.13)
}

@media screen and (min-width: 425px) and (max-width: 1023px){
  .header{
    padding: 28px 16px 28px 16px;
  }
}

@media screen and (min-width: 425px) and (max-width: 768px){
  .header .mid-cont{
    display: none;
  }
}


@media screen and (max-width: 425px){
  .header{
    padding: 28px 16px 28px 16px;
  }
  .header .mid-cont{
    display: none;
  }
  .logo{
    gap: 27px;
  }
  .header .left-cont .logo-gif {
    height: 15px;
  }
  .header .left-cont .logo-name {
    height: 11px;
  }
  .header .right-cont .burger{
    width: 6px;
    height: 15px;
  }
}

