.modalVideo {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 1);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: 0.3s;
  z-index: 101;
  opacity: 0;
  display: flex;
  align-items: center;
}

.modalVideo.active {
  opacity: 1;
  pointer-events: all;
}

.modalVideo .content {
  background: rgba(0, 0, 0, 1);
  transition: 0.4s all;
  z-index: 102;
  width: 100%;
  padding: 0 4px;
  overflow: scroll;
}

.modalVideo .content::-webkit-scrollbar {
  display: none;
}

.modalVideo .content.active {
  transform: scale(1, 1);
}

.modalVideo .headCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 45px;
}

.modalVideo .headCont p {
  font-family: 'Exo 2', serif;
  font-size: 24px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin: unset;
  letter-spacing: 0.2px;
}

.modalVideo .headCont img {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.modalVideo iframe {
  width: 100%;
  height: 24vh;
}

.modalVideo .footCont {
  margin-top: 36px;
}

.modalVideo .footCont span {
  font-family: 'Exo 2', serif;
  font-size: 12px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.5);
}

.modalVideo .footCont p {
  font-family: 'Exo 2', serif;
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  display: inline-block;
  margin-left: 9px;
  margin-bottom: 10px
}

.modalVideo .loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 103;
}

.modalVideo .loader img {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .modalVideo .headCont {
    margin-top: 50px;
    margin-bottom: 40px;
    max-width: 1305px;
  }

  .modalVideo .headCont p {
    font-size: 34px;
    font-weight: 600;
    margin: unset;
    letter-spacing: 0.2px;
  }

  .modalVideo .headCont img {
    height: 34px;
    width: 34px;
  }

  .modalVideo iframe {
    width: 100%;
    height: 30vh;
    max-height: 460px;
  }

  .modalVideo .footCont span {
    font-size: 16px;
  }

  .modalVideo .footCont p {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .modalVideo .content {
    height: 100%;
  }

  .modalVideo .headCont {
    margin-top: 70px;
    margin-bottom: 40px;
    max-width: 1305px;
  }

  .modalVideo .headCont p {
    font-size: 34px;
    font-weight: 600;
    margin: unset;
    letter-spacing: 0.2px;
  }

  .modalVideo .headCont img {
    height: 34px;
    width: 34px;
  }

  .modalVideo iframe {
    width: 100%;
    height: 40vh;
    max-height: 460px;
    min-height: 200px;
  }

  .modalVideo .footCont {
    margin-top: unset;
    display: flex;
    align-items: center;
  }

  .modalVideo .footCont span {
    font-size: 16px;
    display: block;
  }

  .modalVideo .footCont p {
    font-size: 16px;
    margin-left: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .modalVideo {
    align-items: unset;
  }

  .modalVideo .content {
    padding: 40px 48px;
    height: 100%;
  }

  .modalVideo .headCont {
    margin-top: 35px;
    margin-bottom: 50px;
    max-width: 1305px;
  }

  .modalVideo .headCont p {
    font-size: 38px;
    font-weight: 600;
    margin: unset;
    letter-spacing: 0.2px;
  }

  .modalVideo .headCont img {
    height: 38px;
    width: 38px;
  }

  .modalVideo iframe {
    width: 100%;
    height: 400px;
  }

  .modalVideo .footCont {
    margin-top: unset;
    display: flex;
    align-items: center;
  }

  .modalVideo .footBody {
    margin-left: 65px;
    margin-top: 22px;
  }

  .modalVideo .footCont span {
    font-size: 17px;
    display: block;
    margin-bottom: 5px;
  }

  .modalVideo .footCont p {
    font-size: 17px;
    margin-left: 0;
    margin-bottom: 18px
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .modalVideo {
    align-items: unset;
  }

  .modalVideo .content {
    padding: 40px 48px;
    height: 100%;
  }

  .modalVideo .headCont {
    margin-top: 35px;
    margin-bottom: 50px;
    max-width: 1305px;
  }

  .modalVideo .headCont p {
    font-size: 40px;
    font-weight: 600;
    margin: unset;
    letter-spacing: 0.2px;
  }

  .modalVideo .headCont img {
    height: 40px;
    width: 40px;
  }

  .modalVideo iframe {
    width: 100%;
    height: 450px;
  }

  .modalVideo .footCont {
    margin-top: unset;
    display: flex;
    align-items: center;
  }

  .modalVideo .footBody {
    margin-left: 90px;
    margin-top: 22px;
  }

  .modalVideo .footCont span {
    font-size: 18px;
    display: block;
    margin-bottom: 6px;
  }

  .modalVideo .footCont p {
    font-size: 18px;
    margin-left: 0;
    margin-bottom: 20px
  }
}

@media screen and (min-width: 1400px) {
  .modalVideo {
    align-items: unset;
  }

  .modalVideo .content {
    padding: 40px 68px;
    height: 100%;
  }

  .modalVideo .headCont {
    margin-top: 35px;
    margin-bottom: 67px;
    max-width: 1305px;
  }

  .modalVideo .headCont p {
    font-size: 40px;
    font-weight: 600;
    margin: unset;
    letter-spacing: 0.2px;
  }

  .modalVideo .headCont img {
    height: 40px;
    width: 40px;
  }

  .modalVideo iframe {
    width: 100%;
    height: 480px;
  }

  .modalVideo .footCont {
    margin-top: unset;
    display: flex;
    align-items: center;
  }

  .modalVideo .footBody {
    margin-left: 108px;
    margin-top: 28px;
  }

  .modalVideo .footCont span {
    font-size: 18px;
    display: block;
    margin-bottom: 6px;
  }

  .modalVideo .footCont p {
    font-size: 18px;
    margin-left: 0;
    margin-bottom: 20px
  }
}

@media screen and (max-height: 400px) {
  .modalVideo .headCont {
    margin-top: 40px !important;
  }

  .modalVideo .content {
    height: 100% !important;
    padding-bottom: 40px;
  }

  .modalVideo iframe {
    min-height: 200px;
  }
}
