.recent-works .title-recent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 144px;
}

.recent-works .title-recent h2 {
  margin: 0px;
  padding: 0px;
  margin-left: 8px;
  font-family: 'Exo 2', serif;
  font-size: 32px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0.3px;
}

.recent-works .see-all{
  background: transparent;
  border: none;
  width: 90px;
  padding: 0px;
  margin-right: 8px;
}

.recent-works .see-all img{
  width: 100%;
  transition: transform 0.2s ease;
}

.recent-works .see-all:hover img{
  transform: scale(1.03);
}

.recent-works .see-all:active {
  background: rgb(16, 16, 16);
}

.recent-works .see-all-mob{
  background: transparent;
  border: none;
  width: 90px;
  padding: 0px;
  display: none;
}

.recent-works .see-all-mob img{
  width: 100%;
  transition: transform 0.2s ease;
}

.recent-works .see-all-mob:hover img{
  transform: scale(1.03);
}

.recent-works .see-all-mob:active {
  background: rgb(16, 16, 16);
}


.recent-works .works-cont {
  margin-top: 12px;
  /* padding: 0 4px; */
}

.recent-works .work-cont {
  margin-top: 12px;
 /*  padding: 0 4px; */
}

.recent-works .work-cont .image-cont {
  position: relative;
  margin-top: 16px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.recent-works .work-cont .work-img {
  width: 100%;
  transition: 0.3s;
  display: block;
}

.recent-works .work-cont .mask-cont {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.3s;
}

.recent-works .work-cont .mask-cont .corner1,
.recent-works .work-cont .mask-cont .corner2,
.recent-works .work-cont .mask-cont::before,
.recent-works .work-cont .mask-cont::after {
  content: '';
  position: absolute;
  width: 8vw;
  height: 8vw;
  border: 1px solid white;
  z-index: 1;
}

.recent-works .work-cont .mask-cont::before {
  top: 3vw;
  left: 3vw;
  border-bottom: none;
  border-right: none;
}

.recent-works .work-cont .mask-cont::after {
  bottom: 3vw;
  right: 3vw;
  border-top: none;
  border-left: none;
}

.recent-works .work-cont .mask-cont .corner1 {
  top: 3vw;
  right: 3vw;
  border-bottom: none;
  border-left: none;
}

.recent-works .work-cont .mask-cont .corner2 {
  bottom: 3vw;
  left: 3vw;
  border-top: none;
  border-right: none;
}

.recent-works .work-cont .mask-cont .title {
  position: absolute;
  top: 8vw;
  left: 8vw;
}

.recent-works .work-cont .mask-cont .title h3 {
  font-family: 'Nova Mono', serif;
  font-size: 4vw;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0;
}

.recent-works .work-cont .mask-cont .title p {
  font-family: 'Nova Mono', serif;
  font-size: 4vw;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
}

.recent-works .work-cont .mask-cont .play {
  position: absolute;
  bottom: 8.8vw;
  right: 8vw;
  display: flex;
}

.recent-works .work-cont .mask-cont .play img {
  margin-right: 1.5vw;
  width: 4vw;
  margin-bottom: -0.5vw;
}

.recent-works .work-cont .mask-cont .play span {
  font-family: 'Nova Mono', serif;
  font-size: 4vw;
  line-height: 4vw;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 767px){
  .recent-works .title-recent {
    margin-top: 60px;
  }
  .recent-works .see-all{
    display: none;
  }
  .see-all-btn-container{
    margin-top: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .recent-works .see-all-mob{
    display: flex;
  }

   .recent-works .title-recent h2{
    margin-left: 0px;
  }

}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .recent-works .work-cont .mask-cont .corner1,
  .recent-works .work-cont .mask-cont .corner2,
  .recent-works .work-cont .mask-cont::before,
  .recent-works .work-cont .mask-cont::after {
    width: 40px;
    height: 40px;
  }

   .recent-works .title-recent h2{
    margin-left: 0px;
  }


  .recent-works .work-cont .mask-cont::before {
    top: 18px;
    left: 18px;
  }

  .recent-works .work-cont .mask-cont::after {
    bottom: 18px;
    right: 18px;
  }

  .recent-works .work-cont .mask-cont .corner1 {
    top: 18px;
    right: 18px;
  }

  .recent-works .work-cont .mask-cont .corner2 {
    bottom: 18px;
    left: 18px;
  }

  .recent-works .work-cont .mask-cont .title {
    top: 42px;
    left: 42px;
  }

  .recent-works .work-cont .mask-cont .title h3 {
    font-size: 21px;
  }

  .recent-works .work-cont .mask-cont .title p {
    font-size: 21px;
  }

  .recent-works .work-cont .mask-cont .play {
    bottom: 46px;
    right: 42px;
  }

  .recent-works .work-cont .mask-cont .play img {
    margin-right: 10px;
    width: 21px;
    margin-bottom: -3px;
  }

  .recent-works .work-cont .mask-cont .play span {
    font-size: 21px;
    line-height: 21px;
  }

  .recent-works .work-cont .image-cont {
    margin-top: 12px;
    position: relative;
    height: 271px
  }

  .recent-works .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .recent-works {
    padding-left: 16px;
    padding-right: 16px;
  }

  .recent-works .work-parent {
    padding: 0 6px;
  }

   .recent-works .title-recent h2{
    margin-left: -6px;
  }
  .recent-works .see-all{
    margin-right: -6px;
  }


  .recent-works .works-cont {
    margin-top: 10px;
  }

  .recent-works .work-cont {
    padding: 0;
  }

  .recent-works .work-cont .image-cont {
    margin-top: 12px;
    position: relative;
    height: 193.5px
  }

  .recent-works .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .recent-works .work-cont .mask-cont .corner1,
  .recent-works .work-cont .mask-cont .corner2,
  .recent-works .work-cont .mask-cont::before,
  .recent-works .work-cont .mask-cont::after {
    width: 30px;
    height: 30px;
  }

  .recent-works .work-cont .mask-cont::before {
    top: 12px;
    left: 12px;
  }

  .recent-works .work-cont .mask-cont::after {
    bottom: 12px;
    right: 12px;
  }

  .recent-works .work-cont .mask-cont .corner1 {
    top: 12px;
    right: 12px;
  }

  .recent-works .work-cont .mask-cont .corner2 {
    bottom: 12px;
    left: 12px;
  }

  .recent-works .work-cont .mask-cont .title {
    top: 28px;
    left: 28px;
  }

  .recent-works .work-cont .mask-cont .title h3 {
    font-size: 18px;
  }

  .recent-works .work-cont .mask-cont .title p {
    font-size: 18px;

  }

  .recent-works .work-cont .mask-cont .play {
    bottom: 32px;
    right: 28px;
  }

  .recent-works .work-cont .mask-cont .play img {
    margin-right: 10px;
    width: 18px;
    margin-bottom: -3px;
  }

  .recent-works .work-cont .mask-cont .play span {
    font-size: 18px;
    line-height: 18px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .recent-works {
    padding-left: 16px;
    padding-right: 16px;
  }

  .recent-works .title-recent h2{
    margin-left: 4px;
  }


  .recent-works .work-parent {
    padding: 0 8px;
  }

  .recent-works .works-cont {
    margin-top: 7px;
    padding: 0px 8px;
  }

  .recent-works .work-cont {
    padding: 0;
  }

  .recent-works .work-cont .image-cont {
    margin-top: 16px;
    position: relative;
    height: 256.5px
  }

  .recent-works .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .recent-works .work-cont .mask-cont .corner1,
  .recent-works .work-cont .mask-cont .corner2,
  .recent-works .work-cont .mask-cont::before,
  .recent-works .work-cont .mask-cont::after {
    width: 35px;
    height: 35px;
  }

  .recent-works .work-cont .mask-cont::before {
    top: 15px;
    left: 15px;
  }

  .recent-works .work-cont .mask-cont::after {
    bottom: 15px;
    right: 15px;
  }

  .recent-works .work-cont .mask-cont .corner1 {
    top: 15px;
    right: 15px;
  }

  .recent-works .work-cont .mask-cont .corner2 {
    bottom: 15px;
    left: 15px;
  }

  .recent-works .work-cont .mask-cont .title {
    top: 36px;
    left: 34px;
  }

  .recent-works .work-cont .mask-cont .title h3 {
    font-size: 19px;
  }

  .recent-works .work-cont .mask-cont .title p {
    font-size: 19px;
  }

  .recent-works .work-cont .mask-cont .play {
    bottom: 38px;
    right: 32px;
  }

  .recent-works .work-cont .mask-cont .play img {
    margin-right: 8px;
    width: 19px;
    margin-bottom: -3px;
  }

  .recent-works .work-cont .mask-cont .play span {
    font-size: 19px;
    line-height: 19px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .recent-works {
    padding-left: 16px;
    padding-right: 16px;
  }

  .recent-works .work-parent {
    padding: 0 10px;
  }

  .recent-works .works-cont {
    margin-top: 7px;
    padding: 0px 8px;
  }

  .recent-works .work-cont {
    padding: 0;
  }

  .recent-works .work-cont .image-cont {
    margin-top: 20px;
    position: relative;
    height: 305px
  }

  .recent-works .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .recent-works .work-cont .mask-cont .corner1,
  .recent-works .work-cont .mask-cont .corner2,
  .recent-works .work-cont .mask-cont::before,
  .recent-works .work-cont .mask-cont::after {
    width: 50px;
    height: 50px;
  }

  .recent-works .work-cont .mask-cont::before {
    top: 20px;
    left: 20px;
  }

  .recent-works .work-cont .mask-cont::after {
    bottom: 20px;
    right: 20px;
  }

  .recent-works .work-cont .mask-cont .corner1 {
    top: 20px;
    right: 20px;
  }

  .recent-works .work-cont .mask-cont .corner2 {
    bottom: 20px;
    left: 20px;
  }

  .recent-works .work-cont .mask-cont .title {
    top: 50px;
    left: 46px;
  }

  .recent-works .work-cont .mask-cont .title h3 {
    font-size: 24px;
  }

  .recent-works .work-cont .mask-cont .title p {
    font-size: 24px;
  }

  .recent-works .work-cont .mask-cont .play {
    bottom: 52px;
    right: 46px;
  }

  .recent-works .work-cont .mask-cont .play img {
    margin-right: 12px;
    width: 24px;
    margin-bottom: -4px;
  }

  .recent-works .work-cont .mask-cont .play span {
    font-size: 24px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1400px) {
  .recent-works {
    padding-left: 16px;
    padding-right: 16px;
  }

  .recent-works .title-recent {
    margin-top: 121px;
  }

  .recent-works .see-all {
    width: 90px;
  }

  .recent-works .title-recent h2 {
    letter-spacing: 0;
  }

  .recent-works .works-cont {
    margin-top: 7px;
    padding: 0px 8px;
  }

  .recent-works .work-cont {
    margin-top: 22px;
    padding: 0;
  }

  .recent-works .work-cont .mask-cont .corner1,
  .recent-works .work-cont .mask-cont .corner2,
  .recent-works .work-cont .mask-cont::before,
  .recent-works .work-cont .mask-cont::after {
    width: 55px;
    height: 55px;
  }

  .recent-works .work-cont .mask-cont::before {
    top: 24px;
    left: 24px;
  }

  .recent-works .work-cont .mask-cont::after {
    bottom: 24px;
    right: 24px;
  }

  .recent-works .work-cont .mask-cont .corner1 {
    top: 24px;
    right: 24px;
  }

  .recent-works .work-cont .mask-cont .corner2 {
    bottom: 24px;
    left: 24px;
  }

  .recent-works .work-cont .mask-cont .title {
    top: 58px;
    left: 52px;
  }

  .recent-works .work-cont .mask-cont .title h3 {
    font-size: 26px;
  }

  .recent-works .work-cont .mask-cont .title p {
    font-size: 26px;
  }

  .recent-works .work-cont .mask-cont .play {
    bottom: 62px;
    right: 52px;
  }

  .recent-works .work-cont .mask-cont .play img {
    margin-right: 12px;
    width: 26px;
    margin-bottom: -4px;
  }

  .recent-works .work-cont .mask-cont .play span {
    font-size: 26px;
    line-height: 26px;
  }

  .recent-works .work-cont .image-cont {
    margin-top: 20px;
    position: relative;
    height: 353px
  }

  .recent-works .image-cont  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
