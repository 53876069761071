.home {
  background: #000000;
}

.home .mainImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.home .mainImg img {
  width: 260%;
  max-width: 1200px;
  margin-top: -14px;
  padding: 0px;
}

.home .home-title {
  margin: 72px 0px 144px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.home .home-title img {
  width: 100%;
}
.title{
  width: 440px;
  padding: 0px;
}

.subtitle{
  max-width: 814px;
  padding: 0px;
}

.home .home-title h2 {
  padding: 0px;
  margin: 0px;
  letter-spacing: 0.1px;
  font-family: 'Exo 2', serif;
  font-size: 24px;
  font-weight: 200;
  line-height: 24px;
  padding-top: 80px;
  color: #FFFFFF;
}

@media screen and (max-width: 445px) {
  .home .home-title{
    margin: 65px 0px 76px 0px;
  }
  .home .home-title h2{
    font-size: 16px;
    padding-top: 65px;
  }
  
}

/* @media screen and (min-width: 576px) and (max-width: 767px) {
  .home .home-title {
    padding-bottom: 30px;
  }

  .home .home-title img {
    width: 70%;
    margin-top: 56px;
  }

  .home .home-title h2 {
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    color: #FFFFFF;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .home .mainImg img {
    width: 160%;
    max-width: unset;
    margin-top: -14px;
  }

  .home .home-title {
    padding-bottom: 50px;
  }

  .home .home-title img {
    width: 111%;
    margin-top: 53px;
    margin-left: 4px;
  }

  .home .home-title h2 {
    font-size: 16px;
    line-height: 28px;
    margin-top: 82px;
    margin-left: 10px;
    width: 98%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .home .mainImg img {
    width: 134%;
    max-width: unset;
    margin-top: -14px;
  }

  .home .home-title {
    padding-bottom: 80px;
  }

  .home .home-title img {
    width: 111%;
    margin-top: 53px;
    margin-left: 4px;
  }

  .home .home-title h2 {
    font-size: 20px;
    line-height: 32px;
    margin-top: 108px;
    margin-left: 32px;
    width: 98%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .home .mainImg img {
    width: 122%;
    max-width: unset;
    margin-top: -14px;
  }

  .home .home-title {
    padding-bottom: 100px;
  }

  .home .home-title img {
    width: 111%;
    margin-top: 53px;
    margin-left: 4px;
  }

  .home .home-title h2 {
    font-size: 24px;
    line-height: 36px;
    margin-top: 127px;
    margin-left: 32px;
    width: 98%;
  }
}

@media screen and (min-width: 1400px) {
  .home .mainImg img {
    width: 118%;
    max-width: unset;
    margin-top: -14px;
  }

  .home .home-title {
    padding-bottom: 113px;
  }

  .home .home-title img {
    width: 111%;
    margin-top: 46px;
    margin-left: 4px;
  }

  .home .home-title h2 {
    font-size: 24px;
    line-height: 36px;
    margin-top: 153px;
    margin-left: 33px;
    width: 95%;
  }
}

@media screen and (max-height: 590px) {
  .home .mainImg img {
    width: unset;
    height: 70vh;
  }
} */
