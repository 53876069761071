.menu {
  width: 100vw;
  background: rgb(12 15 33/ 0.6);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 99;
}

.menu.active {
  opacity: 1;
  pointer-events: all;
}

.menu .modal_content {
  background: #ffffff;
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100svh;
  transition: 0.4s all;
  transform: scale(0, 1);
  transform-origin:  right;
  max-width: unset;
  right: 0;
  position: absolute;
  padding: 0 16px;
  z-index: 11;
}

.modal_content.active {
  transform: scale(1, 1);
  overflow: hidden;
}

.menu .top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.menu .top-container .menu-item {
  font-family: 'Exo 2', serif;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #8C8B93;
}

.menu .top-container .closeButton {
  margin-right: 3px;
  cursor: pointer;
}

.menu .top-container .closeButton img {
  width: 25px;
}

.menu .mid-container {
  margin-top: auto;
  margin-bottom: auto;
}

.menu .mid-container .item {
  font-family: 'Exo 2', serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 11vh;
  text-align: left;
  margin-top: 20px;
  cursor: pointer;
}

.menu .mid-container .active {
  font-weight: 600;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.menu .mid-container .active:before {
  content: "";
  background-image: url('../images/dots_horizontalBLACK.png');
  background-size: cover;
  width: 28px;
  height: 10px;
}

.menu .bot-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}


.menu .bot-container button{
  border: none;
  background: transparent;
  padding: 0;
}

.connectBtn{
  width: 75px;
}

.networks-media-container{
  display: flex;
  flex-direction: row;
  gap: 34px;
}

.networks-media{
  width: 21px;
   cursor: pointer;
}

@media screen and (max-width: 575px) {
  .menu .modal_content {
    width: 100vw;
  }
  .networks-media{
    width: 21px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .menu .modal_content {
    width: 70vw;
  }

  .menu .top-container .menu-item {
    font-size: 24px;
  }
  .networks-media{
    width: 35px;
  }
  .connectBtn{

    width: 90px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .menu .modal_content {
    width: 56vw;
    padding: 0 30px;
  }

  .menu .top-container .closeButton {
    margin-right: 0;
  }

  .menu .top-container .menu-item {
    font-size: 24px;
  }

  .menu .top-container .closeButton img {
    width: 25px;
  }

  .menu .mid-container .item {
    line-height: 58px;
    margin-top: 44px;
    font-size: 20px;
  }

  .menu .mid-container .active {
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .menu .mid-container .active:before {
    margin-right: 24px;
  }
  .networks-media{
    width: 35px;
  }
  .connectBtn{

    width: 90px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .menu .modal_content {
    width: 48vw;
    padding: 0 45px;
  }

  .menu .top-container .closeButton {
    margin-right: 0;
  }

  .menu .top-container .menu-item {
    font-size: 24px;
  }

  .menu .top-container .closeButton img {
    width: 25px;
  }

  .menu .mid-container .item {
    line-height: 68px;
    margin-top: 44px;
    font-size: 20px;

  }

  .menu .mid-container .active {
    font-weight: 700;
    display: flex;
    align-items: center;
  }

  .menu .mid-container .active:before {
    margin-right: 24px;
  }
  .networks-media{
    width: 35px;
  }
  .connectBtn{

    width: 90px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .menu .modal_content {
    width: 48vw;
    padding: 0 70px;
  }

  .menu .top-container .closeButton {
    margin-right: 0;
  }

  .menu .top-container .menu-item {
    font-size: 24px;
  }

  .menu .top-container .closeButton img {
    width: 25px;
  }

  .menu .mid-container .item {
    line-height: 68px;
    margin-top: 44px;
    font-size: 20px;
  }

  .menu .mid-container .active {
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  .networks-media{
    width: 35px;
  }
  .connectBtn{

    width: 90px;
  }
}

@media screen and (min-width: 1400px) {
  .menu .modal_content {
    width: 47.2vw;
    padding: 0 80px;
  }

  .menu .top-container .closeButton {
    margin-right: 0;
  }

  .menu .top-container .menu-item {
    font-size: 24px;
  }

  .menu .top-container .closeButton img {
    width: 25px;
  }

  .menu .mid-container .item {
    line-height: 68px;
    margin-top: 44px;
    font-size: 20px;
  }

  .menu .mid-container .active {
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  .networks-media{
    width: 35px;
  }
  .connectBtn{

    width: 90px;
  }
}

@media screen and (max-height: 420px) {
  .menu .top-container {
    margin-top: 3vh;
  }

  .menu .top-container .menu-item {
    font-size: 20px;
    line-height: 12px;
  }

  .menu .top-container .closeButton img {
    width: 8vh;
  }

  .menu .mid-container .item {
    font-size: 20px;
    line-height: 12vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .networks-media{
    width: 35px;
  }
  .connectBtn{
    width: 90px;
  }
}

@media screen and (min-height: 421px) and (max-height: 700px) {
  .menu .mid-container .item {
    margin-top: 10px;
  }
}
